@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  align-items: center;
  opacity: 0.8;
  background-color: colors.$color-dark-blue-primary;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: none;
  padding: variables.$spacing-sm variables.$spacing-md;
  font-weight: variables.$font-weight-regular;
  font-size: variables.$font-size-body-1;
  color: colors.$color-white;
  cursor: pointer;
  outline: none;
  transition: all variables.$transition-duration variables.$transition-timing-function;

  &:hover,
  &:focus {
    opacity: 1;
    background-color: color.adjust(colors.$color-dark-blue-primary, $lightness: -1%);
  }

  &:active {
    transform: scale(0.9);
    background-color: color.adjust(colors.$color-dark-blue-primary, $lightness: -10%);
  }

  &.disabled {
    background-color: colors.$color-dark-blue-primary;
    color: colors.$color-white;
  }

  &.loading {
    &,
    &:hover,
    &:focus {
      cursor: not-allowed;
      background-image: url('./spinner.svg');
      background-repeat: no-repeat;
      background-position: center;
      color: transparent;
    }

    &.size-sm {
      background-size: 20%;
    }

    &.ghost {
      &,
      &:hover,
      &:focus {
        background-image: url('./spinner-dark.svg');
      }
    }
  }
}

.contrast {
  background-color: colors.$color-white-40;
  color: colors.$color-black;

  &:hover,
  &:focus {
    background-color: colors.$color-white-20;
  }

  &:active {
    background-color: colors.$color-grey-accent;
  }

  &.disabled {
    background-color: colors.$color-white-40;
    color: colors.$color-black;
    &:active {
      transform: scale(1);
    }
  }
}

.block {
  display: block;
  width: 100%;
}

.pill {
  border-radius: 90px;
  text-transform: uppercase;

  &.size-xs {
    padding: 4px 8px;
    font-size: variables.$font-size-fine;
  }

  &.size-sm {
    padding: 6px 10px;
    font-size: variables.$font-size-fine;
  }
  &.size-md {
    padding: 14px variables.$spacing-lg;
  }
  &.size-lg {
    padding: variables.$spacing-md variables.$spacing-xl;
  }
}

.secondary {
  background-color: color.adjust(colors.$color-carbon-accent, $lightness: 20%);
  color: colors.$color-black-primary;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: color.adjust(colors.$color-carbon-accent, $lightness: 10%);
  }

  &:active {
    background-color: color.adjust(colors.$color-carbon-accent, $lightness: 5%);
  }

  &.disabled {
    background-color: colors.$color-carbon-tint;
    color: colors.$color-black-primary;
  }
}

.tertiary {
  background-color: colors.$color-carbon-primary;
  color: colors.$color-white;

  &:hover,
  &:focus {
    background-color: color.adjust(colors.$color-carbon-primary, $lightness: -2%);
  }

  &:active {
    background-color: colors.$color-black-secondary;
  }

  &.disabled {
    background-color: colors.$color-carbon-secondary;
    color: colors.$color-white;
  }
}

.disabled {
  opacity: 0.4;
  background-color: #006eff;
  cursor: not-allowed;

  &:hover,
  &:focus {
    opacity: 0.4;
  }
}

.link {
  background-color: transparent;
  border: none;
  color: colors.$color-system-primary;
  box-shadow: none;

  &:hover,
  &:focus {
    opacity: 1;
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }

  &.disabled {
    background-color: transparent;

    &:hover,
    &:focus {
      opacity: 0.4;
    }
  }
}

.ghost {
  background-color: transparent;
  border: 1.5px solid colors.$color-black-40;
  box-shadow: none;
  color: colors.$color-black-60;
  font-weight: variables.$font-weight-medium;

  &:hover,
  &:focus {
    border-color: colors.$color-black-60;
    color: colors.$color-black-80;
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }

  &.disabled {
    background-color: transparent;
    color: colors.$color-black-60;
  }
}

.size {
  &-xs {
    padding: 6px variables.$spacing-sm;
    font-size: variables.$font-size-body-2;
  }
  &-sm {
    padding: 12px variables.$spacing-md;
    font-size: variables.$font-size-body-2;
  }
  &-md {
    padding: variables.$spacing-md variables.$spacing-lg;
  }
  &-lg {
    padding: variables.$spacing-lg variables.$spacing-xl;
  }
}
